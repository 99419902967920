<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Tablevideo from '@components/video/tablevideo'
import Tablesub from '@components/subcategory/tablesub'
import Tablesubinsub from '@components/subcategory/tablesubinsub'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Tablevideo,
    Tablesub,
    Tablesubinsub,

    //  PageHeader
  },
  data() {
    return {
      data: [],
    }
  },
  mounted() {
    this.onLode()
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/category/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
        })
        .catch((err) => {
          // console.log(err)
          // alert(err.response.data.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 v-if="this.data.category_name" class="mb-1 mt-0">{{
          this.data.category_name
        }}</h4>
      </div>
      <div class="col-xl-6">
        <div>
          <router-link :to="`/category/child/create/${this.$route.params.id}`">
            <button
              class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
            >
              <feather type="file-plus"></feather>Create
            </button>
          </router-link>
        </div>
        <router-link :to="`/category/cover/${this.data.category_code}`">
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          >
            <feather type="image"></feather>&nbsp;Cover
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <Tablevideo v-if="data.is_sub == false" :category_code="data._id">
          </Tablevideo>

          <Tablesub
            v-if="data.is_sub == true"
            :category_code="data.category_code"
          >
          </Tablesub>

          <Tablesubinsub
            v-if="data.is_subcategory"
            :category-code="data.category_code"
          ></Tablesubinsub>
        </div>
      </div>
    </div>
  </Layout>
</template>
